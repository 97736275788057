<template>
  <Header title="Cetak Laporan" link1="Akuntansi" link2="Cetak Laporan"></Header>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <h5 class="text-secondary d-inline">Cetak Laporan</h5>
            </div>
            <div class="col-md-8">
              <div class="float-end">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row p-2">
            <div class="col-3"></div>
            <div class="col-md-3">
              <label for="">Dari Tanggal :</label>
              <datepicker class="form-control" v-model="param.date_from" style="display:inherit;width:auto;"/>
            </div>
            <div class="col-md-3">
              <label for="">Sampai Tanggal :</label>
              <datepicker class="form-control" v-model="param.date_to" style="display:inherit;width:auto;"/>
            </div>
            <div class="col-3"></div>
          </div>
          <div class="row text-center">
            <div class="card-group">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Laporan Neraca</h5>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-primary" @click="exportBalanceSheet()"><ion-icon name="download-outline"></ion-icon> Export Excel</button>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Laporan Laba Rugi</h5>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-primary" @click="exportIncomeSummary()"><ion-icon name="download-outline"></ion-icon> Export Excel</button>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Laporan Pendapatan Diterima di Muka</h5>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-primary"><ion-icon name="download-outline"></ion-icon> Export Excel</button>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Laporan Kas Bon</h5>
                </div>
                <div class="card-footer">
                  <button type="button" class="btn btn-primary" @click="exportPettyCashAdvance"><ion-icon name="download-outline"></ion-icon> Export Excel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script language="ts" src="./scripts/report.ts"></script>

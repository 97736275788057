import {
  Vue, Options
} from 'vue-class-component'
import Header from '@/components/Header.vue'
import * as bootstrap from 'bootstrap'
import swal from 'sweetalert'
import utils from '@/utils/utils'
import Datepicker from 'vue3-datepicker'

import { reportFactory } from '@/http/report'

@Options({
  components: {
    Header,
    Datepicker
  },
  data() {
    return {
      param: {
        date_from: null,
        date_to: null
      }
    }
  },
  methods: {
    formatDate: function(date:any) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
      if (month.length < 2)
        month = '0' + month
      if (day.length < 2)
        day = '0' + day
      return [year, month, day].join('-')
    },
    exportBalanceSheet: function() {
      var param = {
        date_from: null,
        date_to: null,
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      Promise.resolve(reportFactory.exportBalanceSheet(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    exportIncomeSummary: function() {
      var param = {
        date_from: null,
        date_to: null,
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      Promise.resolve(reportFactory.exportIncomeSummary(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    exportPettyCashAdvance: function() {
      var param = {
        date_from: null,
        date_to: null,
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      Promise.resolve(reportFactory.exportPettyCashAdvance(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
  },
  async mounted () {

  },
  computed: {

  }
})

export default class Report extends Vue {}

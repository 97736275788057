import axios from 'axios'

class ReportFactory {

  async exportBalanceSheet (data: any) {
    try {
      let url = '/acc/reports/balance_sheet'
      var response = await axios.get(url, {
          responseType: 'arraybuffer',
          params: {
            date_from: data.date_from,
            date_to: data.date_to,
        }
      })
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Laporan_Neraca.xlsx'
      link.click()
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async exportIncomeSummary (data: any) {
    try {
      let url = '/acc/reports/income_summary'
      var response = await axios.get(url, {
          responseType: 'arraybuffer',
          params: {
            date_from: data.date_from,
            date_to: data.date_to,
        }
      })
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Laporan_Laba_Rugi.xlsx'
      link.click()
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async exportPettyCashAdvance (data: any) {
    try {
      let url = '/acc/reports/petty_cash_advance'
      var response = await axios.get(url, {
          responseType: 'arraybuffer',
          params: {
            date_from: data.date_from,
            date_to: data.date_to,
        }
      })
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Laporan_Kas_Bon_Sementara.xlsx'
      link.click()
    } catch (error) {
      console.error(error)
      return false
    }
  }

}

export let reportFactory = new ReportFactory()
